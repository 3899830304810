<template>
  <div class="stake">
    <div class="pc">
      <!-- menu -->
      <div class="menu">
        <div class="left">
          <img
            src="https://img.ohdat.io/producer_c/official_website/assets/home/logo.png"
            @click="home"
          />
          <span @click="home">HOME</span>
          <span @click="about">ABOUT</span>
          <span @click="stake">STAKE</span>
        </div>
        <div class="right">
          <img
            src="https://img.ohdat.io/producer_c/official_website/assets/home/opensea.png"
            @click="opensea"
          />
          <img
            src="https://img.ohdat.io/producer_c/official_website/assets/home/twitter.png"
            @click="twitter"
          />
          <img
            src="https://img.ohdat.io/producer_c/official_website/assets/home/game.png"
            @click="game"
          />
          <!-- <img
              src="https://img.ohdat.io/producer_c/official_website/assets/home/share.png"
            /> -->
        </div>
      </div>
      <!-- banner -->
      <div class="banner">
        <img
          src="https://img.ohdat.io/producer_c/official_website/assets/stake/banner.png"
        />
      </div>
      <div class="box">
        <div class="boxin">
          <div class="left">
            <div class="leftin">
              <div class="top">
                <img
                  src="https://img.ohdat.io/producer_c/official_website/assets/stake/title.png"
                />
              </div>

              <div class="conSure" v-if="loginWallet">
                <div class="address">Hi,{{ walletAddress }}</div>
                <div class="conop">
                  <div>
                    <img
                      src="https://img.ohdat.io/producer_c/official_website/assets/stake/icon.png"
                    />Total Staked:
                  </div>
                  <span>{{ staked_count }}</span>
                </div>
                <div class="conop">
                  <div>
                    <img
                      src="https://img.ohdat.io/producer_c/official_website/assets/stake/icon.png"
                    />Producer Points/Day:
                  </div>
                  <span>{{ rate }}</span>
                </div>
                <div class="conop">
                  <div>
                    <img
                      src="https://img.ohdat.io/producer_c/official_website/assets/stake/icon.png"
                    />Producer Points in Total:
                  </div>
                  <span>{{ producer_points }}</span>
                </div>
              </div>
              <div class="con" v-else>
                Stake your Producer C to earn <br />points and get more
                surprises.
              </div>
              <div class="ps">
                <img
                  src="https://img.ohdat.io/producer_c/official_website/assets/stake/ps.png"
                />
              </div>
              <div class="btn" v-if="!loginWallet" @click="connectWallet">
                <div class="connect">
                  <img
                    src="https://img.ohdat.io/producer_c/official_website/assets/stake/connect.png"
                    class="pc_connect"
                  />
                  <img
                    src="https://img.ohdat.io/producer_c/official_website/assets/stake/connect_m.png"
                    class="m_connect"
                  />
                </div>
              </div>
              <div
                :class="shoped ? 'btn' : 'btns'"
                v-else
                @mouseover="mouseover"
                @mouseleave="mouseleave"
              >
                <div class="shop" v-show="shoped">
                  <img
                    src="https://img.ohdat.io/producer_c/official_website/assets/stake/shop.png"
                  />
                </div>
                <div class="shops" v-show="!shoped">
                  <img
                    src="https://img.ohdat.io/producer_c/official_website/assets/stake/soon.png"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="right rightbox ospan" v-if="!loginWallet">
            Your wallet is not connected, get started now!
          </div>
          <div
            class="right rightbox1"
            v-else-if="loginWallet && producer_country == ''"
          >
            <div class="selList">
              <div class="op">
                Please select region for your iQIYI VIP code:
              </div>
              <div class="sel" :style="seldown ? 'border-bottom:none' : ''">
                <img :src="countryUrl" class="countryIcon" />
                <div class="countryName">{{ countryName }}</div>
                <img
                  src="https://img.ohdat.io/producer_c/official_website/assets/stake/arrow.png"
                  class="selIcon"
                  @click="selShow"
                />
              </div>
              <div class="selin" v-show="seldown">
                <div class="seldown">
                  <div
                    class="list"
                    v-for="(item, index) in options"
                    :key="index"
                    @click="selItem(item)"
                  >
                    <img :src="item.url" />
                    <div>{{ item.item }}</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="submit" @click="submit">
              <img
                src="https://img.ohdat.io/producer_c/official_website/assets/stake/submint.png"
              />
            </div>
          </div>
          <div
            :class="tabActive == 1 ? 'right' : 'right current'"
            v-else-if="loginWallet && producer_country != ''"
          >
            <div class="rightin">
              <div class="top">
                <div class="t_l" @click="tab(1)">
                  <img
                    src="https://img.ohdat.io/producer_c/official_website/assets/stake/cir_black.png"
                    v-if="tabActive == 1"
                  />
                  <img
                    src="https://img.ohdat.io/producer_c/official_website/assets/stake/dd.png"
                    v-else
                  />
                </div>
                <div class="r_l" @click="tab(2)">
                  <img
                    src="https://img.ohdat.io/producer_c/official_website/assets/stake/stake_w.png"
                    v-if="tabActive == 1"
                  />
                  <img
                    src="https://img.ohdat.io/producer_c/official_website/assets/stake/cir_w.png"
                    v-else
                  />
                </div>
              </div>
              <div class="lista" v-show="tabActive == 1">
                <div class="list_l">
                  <div class="list_in" v-if="arrlist && arrlist.length > 0">
                    <div
                      class="listMain"
                      v-for="(item, index) in arrlist"
                      :key="index"
                      @click="stakeList(item)"
                    >
                      <div :class="item.choose ? 'lists listCurrent' : 'lists'">
                        <img :src="item.target_info.cover.url" class="nft" />
                        <div class="sel" v-show="item.choose"></div>
                      </div>
                      <p>#{{ item.token_id }}</p>
                    </div>
                  </div>
                  <div class="listnull" v-else>
                    <div class="con">
                      You do not have any unstaked Producer C, go to Opensea to
                      get one!
                    </div>
                    <div class="opensea" @click="opensea">
                      <img
                        src="https://img.ohdat.io/producer_c/official_website/assets/stake/opensea.png"
                      />
                    </div>
                  </div>
                </div>
                <div class="btnlist" v-if="arrlist && arrlist.length > 0">
                  <div class="btn_l" @click="stakeBtn">
                    <img
                      src="https://img.ohdat.io/producer_c/official_website/assets/stake/staketxt.png"
                    />
                  </div>
                  <div class="btn_r" @click="stakeAll">
                    <img
                      src="https://img.ohdat.io/producer_c/official_website/assets/stake/stakeall.png"
                    />
                  </div>
                </div>
              </div>
              <div class="listb" v-show="tabActive == 2">
                <div class="list_l">
                  <div class="list_in" v-if="arrRedeem && arrRedeem.length > 0">
                    <div
                      class="listMain"
                      v-for="(item, index) in arrRedeem"
                      :key="index"
                      @click="stakeList(item)"
                    >
                      <div :class="item.choose ? 'lists listCurrent' : 'lists'">
                        <img :src="item.target_info.cover.url" class="nft" />
                        <div class="sel" v-show="item.choose"></div>
                      </div>
                      <p>#{{ item.token_id }}</p>
                      <div class="opp">
                        Staked for {{ item.since_created }} Days
                      </div>
                    </div>
                  </div>
                  <div class="listnull" v-else>
                    <div class="con">
                      You do not have any staked Producer C.
                    </div>
                  </div>
                </div>
                <div class="btnlist" v-show="arrRedeem && arrRedeem.length > 0">
                  <div class="btn_r" @click="redeem">
                    <img
                      src="https://img.ohdat.io/producer_c/official_website/assets/stake/stake_black.png"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="step step1" v-show="stepBox">
      <div class="stepin">
        <div class="close" @click="close">
          <img
            src="https://img.ohdat.io/producer_c/official_website/assets/stake/close.png"
          />
        </div>
        <div class="tip"><img :src="oimg" /></div>
        <div class="progress">
          <div class="progressin">
            <div class="circle">
              <div class="exp" v-if="step1Exp">1</div>
              <div v-else>
                <div class="border" v-if="step1ing">
                  <div class="borderin"></div>
                  <div class="num">1</div>
                </div>
                <div class="cir_gray" v-else>1</div>
              </div>
            </div>
            <div class="contxt">{{ step1Txt }}</div>
          </div>

          <div :class="step1Exp ? 'line' : 'line grayline'"></div>
          <div class="progressin">
            <div class="circle">
              <div class="exp" v-if="step2Exp">2</div>
              <div v-else>
                <div class="border" v-if="step2ing">
                  <div class="borderin"></div>
                  <div class="num">2</div>
                </div>
                <div class="cir_gray" v-else>2</div>
              </div>
            </div>
            <div class="contxt">{{ step2Txt }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="step stepLast" v-show="stepedBox">
      <div class="stepin">
        <div class="close" @click="closed">
          <img
            src="https://img.ohdat.io/producer_c/official_website/assets/stake/close.png"
          />
        </div>
        <div class="icon">
          <img
            src="https://img.ohdat.io/producer_c/official_website/assets/stake/alright.png"
            v-if="processed"
          />
          <img
            src="https://img.ohdat.io/producer_c/official_website/assets/stake/error.png"
            v-else
          />
        </div>
        <div class="iconcont" v-if="processed">{{ successful }}</div>
        <div class="iconcont" v-else>{{ errortip }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  connectWallet,
  getCurrentAccount,
  checkChain,
  isApprovedForAll,
  setApprovalForAll,
  stakeNFT,
  redeemNFT,
} from "@/utils/login.js";
import {
  my_producer_c_profile,
  list_all_my_producer_c,
  my_staked_producer_c_list,
  update_producer_country,
} from "@/api/item/index.js";
export default {
  name: "stake",
  data() {
    return {
      loginWallet: false,
      walletAddress: "",
      currentAccounttext: "",
      tabActive: 1,
      arrlist: "",
      arrRedeem: "",
      items: [],
      itemser: [],
      contractAddress: "",
      step1Exp: false,
      step1ing: false,
      step2Exp: false,
      step2ing: false,
      step3Exp: false,
      step3ing: false,
      stepBox: false,
      page: 1,
      staked_count: "",
      producer_points: "",
      rate: "",
      processed: false,
      stepedBox: false,
      step1Txt: "",
      step2Txt: "",
      successful: "",
      errortip: "",
      oimg: "",
      shoped: true,
      producer_country: "",
      seldown: false,
      options: [
        {
          value: 1,
          item: "Chinese Mainland",
          url: "https://img.ohdat.io/producer_c/official_website/assets/stake/China.png",
        },
        {
          value: 2,
          item: "Chinese Hong Kong",
          url: "https://img.ohdat.io/producer_c/official_website/assets/stake/HongKong.png",
        },
        {
          value: 3,
          item: "Chinese Taiwan",
          url: "https://img.ohdat.io/producer_c/official_website/assets/stake/Taiwan.png",
        },
        {
          value: 4,
          item: "France",
          url: "https://img.ohdat.io/producer_c/official_website/assets/stake/France.png",
        },
        {
          value: 5,
          item: "Indonesia",
          url: "https://img.ohdat.io/producer_c/official_website/assets/stake/Indonesia.png",
        },
        {
          value: 6,
          item: "Japan",
          url: "https://img.ohdat.io/producer_c/official_website/assets/stake/Japan.png",
        },
        {
          value: 7,
          item: "Korea",
          url: "https://img.ohdat.io/producer_c/official_website/assets/stake/Korea.png",
        },
        {
          value: 8,
          item: "Malaysia",
          url: "https://img.ohdat.io/producer_c/official_website/assets/stake/Malaysia.png",
        },
        {
          value: 9,
          item: "Philippines",
          url: "https://img.ohdat.io/producer_c/official_website/assets/stake/Philippines.png",
        },
        {
          value: 10,
          item: "Singapore",
          url: "https://img.ohdat.io/producer_c/official_website/assets/stake/Singapore.png",
        },
        {
          value: 11,
          item: "Thailand",
          url: "https://img.ohdat.io/producer_c/official_website/assets/stake/Thailand.png",
        },
        {
          value: 12,
          item: "United States",
          url: "https://img.ohdat.io/producer_c/official_website/assets/stake/America.png",
        },
        {
          value: 13,
          item: "Vietnam",
          url: "https://img.ohdat.io/producer_c/official_website/assets/stake/Vietnam.png",
        },
        {
          value: 14,
          item: "Other",
          url: "https://img.ohdat.io/producer_c/official_website/assets/stake/wen.png",
        },
      ],
      countryUrl:
        "https://img.ohdat.io/producer_c/official_website/assets/stake/wen.png",
      countryName: "Select region",
      region_id: "",
    };
  },
  mounted() {
    this.checkLogIn();
    this.preload();
    this.watchWeb3Event();
  },
  computed: {},
  watch: {},

  methods: {
    selItem(item) {
      console.log(item);
      this.countryUrl = item.url;
      this.countryName = item.item;
      this.region_id = item.value;
      this.seldown = !this.seldown;
    },
    selShow() {
      this.seldown = !this.seldown;
    },
    mouseleave() {
      this.shoped = true;
    },
    mouseover() {
      this.shoped = false;
    },
    close() {
      this.stepBox = false;
    },
    closed() {
      this.stepedBox = false;
    },
    preload() {
      var loading;
      if (window.screen.width < 1024) {
        loading = document.getElementById("loading_m");
      } else {
        loading = document.getElementById("loading");
      }
      let imgs = [
        "https://img.ohdat.io/producer_c/official_website/assets/stake/banner.png",
        "https://img.ohdat.io/producer_c/official_website/assets/stake/title.png",
        "https://img.ohdat.io/producer_c/official_website/assets/stake/icon.png",
        "https://img.ohdat.io/producer_c/official_website/assets/stake/ps.png",
        "https://img.ohdat.io/producer_c/official_website/assets/stake/connect.png",
        "https://img.ohdat.io/producer_c/official_website/assets/stake/connect_m.png",
        "https://img.ohdat.io/producer_c/official_website/assets/stake/shop.png",
        "https://img.ohdat.io/producer_c/official_website/assets/stake/soon.png",
        "https://img.ohdat.io/producer_c/official_website/assets/stake/cir_black.png",
        "https://img.ohdat.io/producer_c/official_website/assets/stake/stake_black.png",
        "https://img.ohdat.io/producer_c/official_website/assets/stake/stake_w.png",
        "https://img.ohdat.io/producer_c/official_website/assets/stake/cir_w.png",
        "https://img.ohdat.io/producer_c/official_website/assets/stake/staketxt.png",
        "https://img.ohdat.io/producer_c/official_website/assets/stake/stakeall.png",
        "https://img.ohdat.io/producer_c/official_website/assets/stake/close.png",
        "https://img.ohdat.io/producer_c/official_website/assets/stake/alright.png",
        "https://img.ohdat.io/producer_c/official_website/assets/stake/error.png",
        "https://img.ohdat.io/producer_c/official_website/assets/stake/tip.png",
        "https://img.ohdat.io/producer_c/official_website/assets/stake/tip1.png",
        "https://img.ohdat.io/producer_c/official_website/assets/stake/boxleft.png",
        "https://img.ohdat.io/producer_c/official_website/assets/stake/leftbtn_m.png",
        "https://img.ohdat.io/producer_c/official_website/assets/stake/gray_m.png",
        "https://img.ohdat.io/producer_c/official_website/assets/stake/rigthbg_m.png",
        "https://img.ohdat.io/producer_c/official_website/assets/stake/sel.png",
        "https://img.ohdat.io/producer_c/official_website/assets/stake/bg.png",
        "https://img.ohdat.io/producer_c/official_website/assets/stake/stake_m.png",
        "https://img.ohdat.io/producer_c/official_website/assets/stake/all_m.png",
        "https://img.ohdat.io/producer_c/official_website/assets/stake/current_m.png",
        "https://img.ohdat.io/producer_c/official_website/assets/stake/tanbg.png",
        "https://img.ohdat.io/producer_c/official_website/assets/home/meau.png",
        "https://img.ohdat.io/producer_c/official_website/assets/stake/leftbtn.png",
        "https://img.ohdat.io/producer_c/official_website/assets/stake/gray.png",
        "https://img.ohdat.io/producer_c/official_website/assets/stake/rigthbg.png",
        "https://img.ohdat.io/producer_c/official_website/assets/stake/stake.png",
        "https://img.ohdat.io/producer_c/official_website/assets/stake/all.png",
        "https://img.ohdat.io/producer_c/official_website/assets/stake/current.png",
        "https://img.ohdat.io/producer_c/official_website/assets/stake/Korea.png",
        "https://img.ohdat.io/producer_c/official_website/assets/stake/America.png",
        "https://img.ohdat.io/producer_c/official_website/assets/stake/France.png",
        "https://img.ohdat.io/producer_c/official_website/assets/stake/HongKong.png",
        "https://img.ohdat.io/producer_c/official_website/assets/stake/Indonesia.png",
        "https://img.ohdat.io/producer_c/official_website/assets/stake/Japan.png",
        "https://img.ohdat.io/producer_c/official_website/assets/stake/Malaysia.png",
        "https://img.ohdat.io/producer_c/official_website/assets/stake/Philippines.png",
        "https://img.ohdat.io/producer_c/official_website/assets/stake/Singapore.png",
        "https://img.ohdat.io/producer_c/official_website/assets/stake/Taiwan.png",
        "https://img.ohdat.io/producer_c/official_website/assets/stake/Thailand.png",
        "https://img.ohdat.io/producer_c/official_website/assets/stake/Vietnam.png",
        "https://img.ohdat.io/producer_c/official_website/assets/stake/arrow.png",
        "https://img.ohdat.io/producer_c/official_website/assets/stake/China.png",
        "https://img.ohdat.io/producer_c/official_website/assets/stake/rigthbgbox.png",
        "https://img.ohdat.io/producer_c/official_website/assets/stake/rigthbgbox_m.png",
        "https://img.ohdat.io/producer_c/official_website/assets/stake/subbg.png",
        "https://img.ohdat.io/producer_c/official_website/assets/stake/subbg_m.png",
        "https://img.ohdat.io/producer_c/official_website/assets/stake/submint.png",
        "https://img.ohdat.io/producer_c/official_website/assets/stake/wen.png",
        "https://img.ohdat.io/producer_c/official_website/assets/stake/all_hover.png",
        "https://img.ohdat.io/producer_c/official_website/assets/stake/connect_hover.png",
        "https://img.ohdat.io/producer_c/official_website/assets/stake/stake_hover.png",
        "https://img.ohdat.io/producer_c/official_website/assets/stake/dd.png",
        "https://img.ohdat.io/producer_c/official_website/assets/stake/opensea.png",
      ];
      for (let img of imgs) {
        let image = new Image();
        image.src = img;
        image.onload = () => {
          this.preloadCount++;
          // 计算图片加载的百分数，绑定到percent变量
          let percentNum = Math.floor((this.preloadCount / imgs.length) * 100);
          this.percent = `${percentNum}%`;
          // console.log(this.percent);
          if (percentNum == 100) {
            // console.log(888);
            loading.classList.add("loadingtransform");
            // console.log(loading.classList);
          }
        };
      }
    },
    async ListMyItem() {
      let res = await list_all_my_producer_c();
      this.arrlist = "";
      if (res.code == 200) {
        this.arrlist = res.data.circulate_list;
      }
    },
    async ListMyStake() {
      let res = await my_staked_producer_c_list();
      this.arrRedeem = "";
      if (res.code == 200) {
        this.arrRedeem = res.data.list;
      }
    },
    stakeList(item) {
      if (Object.prototype.hasOwnProperty.call(item, "choose")) {
        item["choose"] = !item["choose"];
      } else {
        this.$set(item, "choose", true);
      }
    },
    stakeBtn() {
      var that = this;
      that.items = [];
      that.arrlist.forEach((item) => {
        if (item.choose) {
          that.items.push(Number(item.token_id));
        }
        if (Object.prototype.hasOwnProperty.call(item, "choose")) {
          item["choose"] = false;
        } else {
          that.$set(item, "choose", false);
        }
      });
      if (that.items == "") {
        that.$message.error("Please select NFT to stake.");
      } else {
        console.log(that.items);
        that.btn(1);
      }
    },
    async stakeAll() {
      var that = this;

      const params = {
        type: 1,
      };
      let res = await list_all_my_producer_c(params);
      that.itemser = [];
      res.data.circulate_token_ids.forEach((item) => {
        that.itemser.push(Number(item));
      });
      console.log(that.itemser);
      if (that.itemser == "") {
        that.$message.error("Please select NFT to stake.");
      } else {
        that.btn(2);
      }
    },
    async btn(i) {
      var that = this;
      that.stepBox = true;
      that.oimg =
        "https://img.ohdat.io/producer_c/official_website/assets/stake/tip.png";
      that.step1Txt = "Checking wallet status";
      that.step2Txt = "Confirm staking";
      that.step1Exp = false;
      that.step1ing = true;
      that.step2Exp = false;
      that.step2ing = false;
      await checkChain();
      // 第一步完成进入第二步
      that.step1Exp = true;
      that.step2Exp = false;
      that.step2ing = true;
      if (that.contractAddress == "") {
        await that.MyProfile();
      }
      var currentAccount = await getCurrentAccount();
      that.go_stake(currentAccount, i);
    },
    async MyProfile() {
      let res = await my_producer_c_profile();
      if (res.code == 200) {
        this.contractAddress = res.data.contract_address;
        this.staked_count = res.data.staked_count;
        this.producer_points = res.data.producer_points;
        this.rate = res.data.rate;
        this.producer_country = res.data.producer_region;
      }
    },
    go_stake(e, i) {
      var that = this;
      var stakelist;
      if (i == 1) {
        stakelist = that.items;
      } else {
        stakelist = that.itemser;
      }
      stakeNFT(e, stakelist, that.contractAddress, function (sender) {
        sender
          .on("transactionHash", function (hash) {
            console.log("hash==", hash);
          })
          .on("confirmation", function (confirmationNumber, receipt) {
            console.log("confirmationNumber", confirmationNumber);
          })
          .on("receipt", function (receipt) {
            console.log("receipt", receipt);
            // 第三步完成
            that.step1Exp = true;
            that.step2Exp = true;
            // that.step3Exp = true;
            setTimeout(() => {
              that.stepBox = false;
              that.stepedBox = true;
              that.successful = "Staking succeeded";
              that.processed = true;
            }, 3000);
            that.ListMyItem();
            that.ListMyStake();
            that.MyProfile();
          })
          .on("error", function (error, receipt) {
            // If the transaction was rejected by the network with a receipt, the second parameter will be the receipt.
            console.log(error);
            that.ListMyItem();
            that.ListMyStake();
            that.MyProfile();
            that.step1Exp = false;
            that.step1ing = false;
            that.step2Exp = false;
            that.step2ing = false;
            that.stepBox = false;
            that.stepedBox = true;
            that.processed = false;
            that.errortip = "Staking failed";
          });
      });
    },
    async redeem() {
      var that = this;
      that.itemsed = [];
      that.arrRedeem.forEach((item) => {
        if (item.choose) {
          that.itemsed.push(Number(item.token_id));
        }
        if (Object.prototype.hasOwnProperty.call(item, "choose")) {
          item["choose"] = false;
        } else {
          that.$set(item, "choose", false);
        }
      });
      if (that.itemsed == "") {
        that.$message.error("Please select NFT to unstake.");
      } else {
        that.stepBox = true;
        that.oimg =
          "https://img.ohdat.io/producer_c/official_website/assets/stake/tip1.png";
        that.step1Txt = "Checking wallet status";
        that.step2Txt = "Confirm unstaking";
        that.step1Exp = false;
        that.step1ing = true;
        that.step2Exp = false;
        that.step2ing = false;
        await checkChain();
        // 第一步完成进入第二步
        that.step1Exp = true;
        that.step2Exp = false;
        that.step2ing = true;
        var currentAccount = await getCurrentAccount();
        var that = this;

        redeemNFT(
          currentAccount,
          that.itemsed,
          that.contractAddress,
          function (sender) {
            sender
              .on("transactionHash", function (hash) {
                console.log("hash==", hash);
              })
              .on("confirmation", function (confirmationNumber, receipt) {
                console.log("confirmationNumber", confirmationNumber);
              })
              .on("receipt", function (receipt) {
                console.log("receipt", receipt);
                that.step1Exp = true;
                that.step2Exp = true;
                setTimeout(() => {
                  that.stepBox = false;
                  that.stepedBox = true;
                  that.processed = true;
                  that.successful = "Unstaking succeeded";
                }, 3000);
                that.ListMyItem();
                that.ListMyStake();
                that.MyProfile();
              })
              .on("error", function (error, receipt) {
                that.step1Exp = false;
                that.step1ing = false;
                that.step2Exp = false;
                that.step2ing = false;
                that.stepedBox = true;
                that.stepBox = false;
                that.processed = false;
                that.errortip = "Unstaking failed";
                // that.redschedule = false;
                that.ListMyItem();
                that.ListMyStake();
                that.MyProfile();
                // If the transaction was rejected by the network with a receipt, the second parameter will be the receipt.
                console.log(error);
                // that.$store.dispatch("mask/closeMask", "stepState");
              });
          }
        );
      }
    },
    tab(num) {
      if (this.loginWallet) {
        if (num == 1) {
          this.tabActive = 1;
        } else {
          this.tabActive = 2;
        }
      }
    },
    async submit() {
      if (this.region_id == "") {
        this.$message.error("Please select region for your iQIYI VIP code.");
      } else {
        const params = {
          region_id: this.region_id,
        };
        let data = await update_producer_country(params);
        console.log(data);
        if (data.code == 200) {
          this.producer_country = "666";
        }
      }
    },
    async checkLogIn() {
      let currentAccount = await getCurrentAccount();
      if (currentAccount) {
        if (localStorage.getItem("wallet_address") != currentAccount) {
          localStorage.setItem("wallet_address", currentAccount);
        }
        this.currentAccounttext = currentAccount;
        this.loginWallet = true;
        this.walletAddress =
          currentAccount.substr(0, 4) + "***" + currentAccount.substr(-4);
        this.MyProfile();
        this.ListMyItem();
        this.ListMyStake();
      } else {
        this.loginWallet = false;
      }
    },
    async connectWallet() {
      let currentAccount = await getCurrentAccount();
      if (currentAccount == null) {
        await connectWallet();
      }
      this.loginWallet = true;
    },
    watchWeb3Event() {
      var that = this;
      ethereum.on("disconnect", (res) => {
        console.log("已断开网络", res);
      });
      ethereum.on("connect", function (res) {
        console.log("已连接到网络", res);
      });
      ethereum.on("accountsChanged", function (res) {
        if (res[0]) {
          if (localStorage.getItem("wallet_address") != res[0]) {
            localStorage.setItem("wallet_address", res[0]);
          }
          that.currentAccounttext = res[0];
          that.walletAddress = res[0].substr(0, 4) + "***" + res[0].substr(-4);
          that.loginWallet = true;
          that.ListMyItem();
          that.ListMyStake();
          that.MyProfile();
        } else {
          that.loginWallet = false;
        }
      });
      ethereum.on("chainChanged", function (res) {
        const network = parseInt(res, 16);
        console.log(`networkID`, res, network);
        that.$store
          .dispatch("web3/set_network", network)
          .then((res) => {
            if (
              network == process.env.VUE_APP_MAIN_NETWORK ||
              network == process.env.VUE_APP_MAIN_NETWORK2
            ) {
            } else {
            }
          })
          .catch((err) => {});
      });
      ethereum.on("message", (msg) => console.log(msg, 11111));
    },

    opensea() {
      window.open("https://opensea.io/collection/producerc");
    },
    twitter() {
      window.open("https://twitter.com/producerc_nft");
    },
    game() {
      window.open("https://discord.gg/producerc");
    },
    Menu() {
      this.menu_status = !this.menu_status;
    },
    home() {
      this.$router.push("/");
    },
    about() {
      this.$router.push("/about");
    },
    stake() {
      this.$router.push("/stake");
    },
  },
  beforeDestroy() {},
};
</script>
<style src="./index.scss" lang="scss" scoped></style>
<style src="./index.mobile.scss" lang="scss" scoped></style>