import request from '@/utils/request'
import baseURL from '@/utils/baseURL'

// 质押列表
export function list_all_my_producer_c(query) {
  return request({
    url: baseURL["item"] + `/v2/item/list_all_my_producer_c`,
    method: "get",
    params: query,
  });
}

// 列表
export function my_staked_producer_c_list(query) {
  return request({
    url: baseURL["item"] + `/v2/nft_stake/my_staked_producer_c_list`,
    method: "get",
    params: query,
  });
}

// 我的质押信息
export function my_producer_c_profile() {
    return request({
      url: baseURL["item"] + `/v2/account/my_producer_c_profile`,
      method: "get",
    });
  }

// 获取国家
export function update_producer_country(data) {
    return request({
      url: baseURL["item"] + `/v2/account/update_producer_region`,
      method: "post",
      data,
    });
}

